import { Link } from 'react-router-dom'
import { products } from '../constants'

const Products = () => {
  return (
    <section className='flex w-full py-20'>
      <div className='w-[80%] max-w-[1440px] mx-auto flex flex-col justify-center'>
        <div className='w-full md:w-[60%] mx-auto flex flex-col justify-center items-center'>
          <h1 className='font-bold text-5xl font-title mb-4'>Products</h1>
          <h2 className='font-bold text-2xl font-title mb-6 text-center'>
            Enhanced Customer <span className='text-logo-1'>Service</span>{' '}
            Experience
          </h2>
          <p className='text-center font-light text-d-2'>
            Committed to excellence, we deliver top-quality materials and
            services, ensuring we can always find what you need. Our focus on
            customer satisfaction, environmental stewardship, and innovation
            drives our growth.
          </p>
        </div>
        <div className='flex flex-wrap mt-12 md:w-[90%] mx-auto'>
          {products.map((product) => (
            <Link
              to={product.url}
              className='w-full md:w-1/2 lg:w-1/3 mb-4'
              key={product.id}
              style={{ cursor: 'pointer' }}
            >
              <div className='flex flex-col justify-between h-full p-4 m-2 shadow-pd rounded-md group transition duration-300 ease-in-out border-2 border-w-1 hover:border-2 hover:border-logo-1'>
                <div className='mt-8'>
                  <div className='flex justify-center items-center w-24 h-24 rounded-full transition duration-300 bg-logo-1 group-hover:bg-w-1 mx-auto shadow-pi text-w-1 group-hover:text-logo-1'>
                    {product.icon}
                  </div>
                  <div className='text-center mt-5'>
                    <h2 className='font-title font-bold text-xl transition duration-300 group-hover:text-logo-1'>
                      {product.title}
                    </h2>
                    <p className='font-light text-[0.95rem] text-center py-4 duration-300 group-hover:text-logo-2'>
                      {product.desc}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Products
