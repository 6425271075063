import React from 'react'
// import { facebook, instagram, linkedIn } from '../assets'
import { footerContactInfo } from '../constants'

const Footer = () => {
  return (
    <footer className='flex w-full py-12 bg-logo-2'>
      <div className='w-[80%] max-w-[1440px] mx-auto flex flex-col'>
        <div className='flex flex-col justify-center lg:flex-row gap-2'>
          <div className='flex-1 flex flex-col px-2'>
            <h2 className='font-title font-semibold text-3xl md:text-3xl text-w-1'>
              67 Supply Inc.
            </h2>
            <p className='font-light text-w-4 mt-8'>
              Future growth is our goal and can only be achieved through respect
              of our customers, our environment and our offering.
            </p>
            <div className='flex flex-col mt-4'>
              {footerContactInfo.map((info) => (
                <div className='flex flex-col mb-3' key={info.id}>
                  <div className='flex flex-row items-center flex-nowrap'>
                    <img src={info.icon} alt={info.title} width={12} />
                    <h3 className='font-title ml-3 text-w-1 font-medium text-lg capitalize'>
                      {info.title}
                    </h3>
                  </div>

                  <div className='ml-6 text-sm text-w-4 font-light'>
                    {info.contents.map((item) => (
                      <div key={item.id}>
                        {item.isEmail ? (
                          <p>
                            {item?.emailName}:{' '}
                            <a
                              href={`mailto:${item.info}`}
                              className='underline underline-offset-2'
                            >
                              {item.info}
                            </a>
                          </p>
                        ) : (
                          <p>{item.info}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className='flex flex-rol flex-nowrap gap-3 mt-12'>
              <img src={facebook} alt='facebook icon' width={22} />
              <img src={instagram} alt='instagram icon' width={22} />
              <img src={linkedIn} alt='linkedIn icon' width={22} />
            </div> */}
          </div>

          <div className='flex-1 flex justify-center'>
            {/* <iframe
              src='https://d32r1sh890xpii.cloudfront.net/widgets/crude/crudechart.html'
              title='Crude Chart'
              width='206'
              height='220'
            ></iframe> */}
            <iframe
              title='symbol overview TradingView widget'
              lang='en'
              id='tradingview_407c4'
              frameBorder='0'
              allowtransparency='true'
              scrolling='no'
              src='https://s.tradingview.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22WTI%20Crude%20Chart%22%2C%22TVC%3AUSOIL%7CALL%22%5D%2C%5B%22Brent%20Crude%20Chart%22%2C%22TVC%3AUKOIL%7CALL%22%5D%5D%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22calc(400px%20-%2032px)%22%2C%22colorTheme%22%3A%22light%22%2C%22gridLineColor%22%3A%22%23F0F3FA%22%2C%22fontColor%22%3A%22%23787B86%22%2C%22chartType%22%3A%22area%22%2C%22lineColor%22%3A%22%232196F3%22%2C%22topColor%22%3A%22%23E3F2FD%22%2C%22page-uri%22%3A%22xtremeoilfield.ca%2F%22%2C%22utm_source%22%3A%22xtremeoilfield.ca%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%7D'
              style={{ width: '100%', height: 'calc(368px)' }}
            ></iframe>
          </div>
        </div>
        <div>
          {/* <hr className='mt-12 border-none h-[1px] bg-w-4' /> */}
          <p className='mt-12 text-w-5 text-left font-light'>
            &copy; {new Date().getFullYear()}{' '}
            <a
              href='https://www.phoenixaminc.com/'
              className='underline underline-offset-2'
            >
              Phoenix Advertising & Marketing
            </a>
            . All rights reserved
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
