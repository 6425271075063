import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { flangesfittings, hero_bg } from '../../assets'

const forgedSteelFittings = [
  'Size: 1/8” through 4”',
  'Pressure: 2000#, 3000#, 6000# (9000# available upon request)',
  'Connections: Threaded and Socketweld',
  'Grade(s): SA105N, SA350 LF2 (Other grades available upon request)',
]
const forgedSteelFlanges = [
  'Size: ½” through 24” (Larger sizes available upon request)',
  'Class:150# through 2500# ANSI',
  'Class: 2000# through 20,000# API',
  'Schedule: Standard through Double Extra Heavy',
  'Grade(s): A359, SA105N, SA350 LF2, &amp; A4130 (Other grades available uponrequest)',
]

const swageNipples = [
  'Size: 1/8” through 8”',
  'Schedule: Standard through Double Extra Heavy',
  'Connections: Beveled, Threaded, and Plain',
  'Combination Ends Available',
  'Swage: Concentric and Eccentric',
  'NPT, EUE, NUE',
]

const FlangesAndFittings = () => {
  return (
    <div>
      <Navbar />
      <Header title='Flanges & Fittings' img={hero_bg} />
      <div className='flex flex-col md:flex-row my-12 max-w-[1440px] mx-auto'>
        <div className='mx-auto flex-1 flex justify-center '>
          <img
            src={flangesfittings}
            alt='flanges & fittings'
            className='w-[550px]'
          />
        </div>
        <div className='flex-1'>
          <div className='pt-8 md:px-16 px-8'>
            <div>
              <h1 className='text-2xl font-semibold text-d-2 mb-6'>
                Forged Steel Fittings
              </h1>
              <div className='ml-8'>
                {forgedSteelFittings.map((item, index) => (
                  <p className='font-light mb-2' key={index}>
                    <span className='text-logo-1 text-sm mr-1'>▶</span> {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className='mt-8 md:px-16 px-8'>
            <div>
              <h1 className='text-2xl font-semibold text-d-2 mb-6'>
                Forged Steel Flanges: ANSI/AP
              </h1>
              <div className='ml-8'>
                {forgedSteelFlanges.map((item, index) => (
                  <p className='font-light mb-2' key={index}>
                    <span className='text-logo-1 text-sm mr-1'>▶</span> {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className='mt-8 md:px-16 px-8'>
            <div>
              <h1 className='text-2xl font-semibold text-d-2 mb-6'>
                INDUSTRIAL SWAGE NIPPLES / BULL PLUGS
              </h1>
              <div className='ml-8'>
                {swageNipples.map((item, index) => (
                  <p className='font-light mb-2' key={index}>
                    <span className='text-logo-1 text-sm mr-1'>▶</span> {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default FlangesAndFittings
