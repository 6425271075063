import React from 'react'
import { mission } from '../assets'
import { Link } from 'react-router-dom'

const HomeMission = () => {
  return (
    <section
      className='flex justify-center items-center w-full py-28'
      style={{
        backgroundImage: `url(${mission})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='flex justify-center items-center'>
        <div className='flex flex-col text-center bg-logo-2 bg-opacity-90 w-[90%] md:w-[70%] lg:w-[50%] py-28 px-16'>
          <h1 className='font-title text-3xl md:text-5xl font-bold text-w-1 mb-12'>
            Mission Statement
          </h1>
          <p className='text-w-1 font-light tracking-[0.5px] mb-16'>
            Our goal is to align ourselves with key vendors and to serve key
            customers within the oil and gas industry. Our highly experienced
            staff is dedicated to customer service expected of us. We are
            already looking to expand our offering in other areas within the
            very near future.
          </p>
          <Link
            to={'/about'}
            className='text-center hover:text-w-1 text-logo-1 text-[1.2rem] underline-offset-4'
          >
            <span className='underline'>More About Us</span> →
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeMission
