import React from 'react'
import { polishRodsContent } from '../constants'

const PolishRods = () => {
  return (
    <div className='border-2 p-6 text-left'>
      <h1 className='font-medium mb-3'>{polishRodsContent.main}</h1>
      {polishRodsContent.details.map((item) => (
        <p key={item.id} className='font-light mb-3'>
          <span className='font-bold'>{item.title}</span> -{' '}
          <span>{item.desc}</span>
        </p>
      ))}
    </div>
  )
}

export default PolishRods
