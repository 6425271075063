import { useEffect, useRef, useState } from 'react'
import { logo_content } from '../assets'
import { navigation } from '../constants'
import { Link, useLocation } from 'react-router-dom'

const Navbar = () => {
  const pathName = useLocation().pathname
  const [open, setOpen] = useState(false)
  const [flyer, setFlyer] = useState(false)
  // const [otherFlyer, setOtherFlyer] = useState(false)
  const flyerRef = useRef(null)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
    const handleClickOutside = (event) => {
      if (flyerRef.current && !flyerRef.current.contains(event.target)) {
        setFlyer(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {/* This example requires Tailwind CSS v2.0+ */}
      <div className='relative bg-white'>
        <div className='max-w-7xl mx-auto px-4 sm:px-6'>
          <div className='flex justify-between items-center py-6 md:justify-start md:space-x-10 '>
            <div className='flex justify-start lg:w-0 lg:flex-1'>
              <Link to='/'>
                <span className='sr-only'>67supply</span>
                <img
                  className='h-14 w-auto sm:h-10'
                  src={logo_content}
                  alt='logo'
                />
              </Link>
            </div>
            <div className='-mr-2 -my-2 md:hidden'>
              <button
                type='button'
                className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                onClick={() => setOpen(!open)}
              >
                <span className='sr-only'>Open menu</span>
                {/* Heroicon name: outline/menu */}
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  aria-hidden='true'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </button>
            </div>
            <nav className='hidden md:flex space-x-10'>
              {navigation.map((item) => (
                <div key={item.id}>
                  {item.hasDropDown ? (
                    <div className='relative' ref={flyerRef}>
                      <button
                        type='button'
                        className="
                          group bg-white rounded-md text-d-1 inline-flex items-center text-base font-medium hover:text-logo-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-1 pb-8'
                         "
                        onClick={() => setFlyer(!flyer)}
                        onMouseEnter={() => setFlyer(!flyer)}
                      >
                        <Link to={item.url}>{item.title}</Link>

                        <svg
                          className={
                            flyer === true
                              ? 'transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200'
                              : 'transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                          }
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 20 20'
                          fill='currentColor'
                          aria-hidden='true'
                        >
                          <path
                            fillRule='evenodd'
                            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                            clipRule='evenodd'
                          />
                        </svg>
                      </button>

                      <div
                        onMouseLeave={() => setFlyer(false)}
                        className={
                          flyer
                            ? 'opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'
                            : 'hidden'
                        }
                      >
                        <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                          <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                            {item.subMenu.map((subItem) => (
                              <Link
                                key={subItem.id}
                                to={subItem.url}
                                className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
                              >
                                <div className='ml-4'>
                                  <p className='text-base font-medium text-gray-900'>
                                    {subItem.title}
                                  </p>
                                  <p className='mt-1 text-sm text-gray-500'>
                                    {subItem.desc}
                                  </p>
                                </div>
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link
                      to={item.url}
                      className={`text-base font-medium  hover:text-logo-1 ${
                        item.url === pathName
                          ? 'text-logo-1 underline'
                          : 'text-d-1'
                      }`}
                    >
                      {item.title}
                    </Link>
                  )}
                </div>
              ))}

              {/* <div>
                <div className='relative'>
                  <button
                    type='button'
                    className="
                          group bg-white rounded-md text-d-1 inline-flex items-center text-base font-medium hover:text-logo-1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-1 pb-8'
                         "
                    onClick={() => setOtherFlyer(!otherFlyer)}
                    onMouseEnter={() => setOtherFlyer(!otherFlyer)}
                  >
                    <p>Other</p>

                    <svg
                      className={
                        otherFlyer === true
                          ? 'transform rotate-180 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-out duration-200'
                          : 'transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      }
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  </button>

                  <div
                    onMouseLeave={() => setOtherFlyer(false)}
                    className={
                      otherFlyer
                        ? 'opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2'
                        : 'hidden'
                    }
                  >
                    <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden'>
                      <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8'>
                        {navOther.map((subItem) => (
                          <Link
                            key={subItem.id}
                            to={subItem.url}
                            className='-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50'
                          >
                            <div className='ml-4'>
                              <p className='text-base font-medium text-gray-900'>
                                {subItem.title}
                              </p>
                              <p className='mt-1 text-sm text-gray-500'>
                                {subItem.desc}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </nav>
            <div className='hidden md:flex items-center justify-end md:flex-1 lg:w-0'>
              <Link
                to={'/contactus'}
                className='ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-logo-1 hover:bg-logo-2'
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide based on mobile menu state. */}
        <div
          className={
            open
              ? 'opacity-100 scale-100 ease-out duration-200 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10'
              : 'hidden'
          }
        >
          <div className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50'>
            <div className='pt-5 pb-6 px-5'>
              <div className='flex items-center justify-between'>
                <div>
                  <img
                    className='h-14 w-auto'
                    src={logo_content}
                    alt='67supply logo'
                  />
                </div>
                <div className='-mr-2'>
                  <button
                    type='button'
                    className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                    onClick={() => setOpen(!open)}
                  >
                    <span className='sr-only'>Close menu</span>
                    {/* Heroicon name: outline/x */}
                    <svg
                      className='h-6 w-6'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      aria-hidden='true'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className='mt-6'>
                <nav className='grid gap-y-8'>
                  {navigation.map((item) => (
                    <div
                      key={item.id}
                      className={`-m-3 p-3 flex items-center rounded-md ${
                        item.hasDropDown || 'hover:bg-gray-50'
                      }`}
                    >
                      {item.hasDropDown ? (
                        <div className='ml-3 text-base font-medium text-gray-900 w-full'>
                          <Link to={item.url}>{item.title}</Link>
                          <div className='flex flex-col w-full'>
                            {item.subMenu.map((subItem) => (
                              <Link
                                to={subItem.url}
                                key={subItem.id}
                                className='mt-2 ml-3 p-1 text-base font-medium text-gray-900 w-full hover:bg-gray-50'
                              >
                                {subItem.title}
                              </Link>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <Link
                          to={item.url}
                          className='ml-3 text-base font-medium text-gray-900'
                        >
                          {item.title}
                        </Link>
                      )}
                    </div>
                  ))}
                </nav>
              </div>
            </div>
            <div className='py-6 px-5 space-y-6'>
              <div>
                <Link
                  to={'/contactus'}
                  className='w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-logo-1 hover:bg-logo-2'
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
