import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { service_bg } from '../assets'
import { services } from '../constants'
import Content from '../components/Content'

const ServicesPage = () => {
  return (
    <div>
      <Navbar />
      <Header title='Our Services' img={service_bg} />
      <div className='flex flex-col justify-center items-center'>
        {services.map((item, index) => (
          <Content key={item.id} item={item} index={index} />
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default ServicesPage
