import React from 'react'

const PipeTable = () => {
  return (
    <table className='border-[1px]'>
      <tbody>
        <tr>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  &nbsp;
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  Size
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Outer
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  Diameter
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  5
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  10
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  20
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  30
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  40
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  &nbsp;
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  Standard
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  60
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  80
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  &nbsp;
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  X-Heavy
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Scheduler
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  100
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  120
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  140
                </font>
              </b>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  Schedule
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  160
                </font>
              </b>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <b>
                <font face='Arial' size='1'>
                  &nbsp;
                </font>
              </b>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <b>
                <font face='Arial' size='1'>
                  XX-Heavy
                </font>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1/8
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              0.405
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .035
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .138
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .049
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .1863
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .068
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.447
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .068
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.447
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .095
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .3145
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .095
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .3145
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1/4
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              0.540
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .049
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .2570
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .3297
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .088
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .4248
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .088
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .4248
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .119
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .5351
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .119
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .5351
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              3/8
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              0.675
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .049
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .3276
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .4235
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .091
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .5676
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .091
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .5676
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .126
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .7388
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .126
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .7388
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1/2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              0.840
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .5383
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .6710
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .8510
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .8510
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .147
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.088
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .147
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.088
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .187
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.304
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .294
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.714
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              3/4
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1.050
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .6838
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .8572
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .113
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.131
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .113
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.131
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .154
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.474
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .154
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.474
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.937
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .308
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.441
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1.315
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                .8678
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .190
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.404
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .133
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.679
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .133
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.679
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .179
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.172
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .179
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.172
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.844
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .358
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.659
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1 1/4
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1.660
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.107
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.806
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .140
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.273
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .140
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.273
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .191
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.997
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .191
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.997
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.765
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .382
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.214
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1 1/2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              1.900
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.274
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.638
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .145
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.718
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .145
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.718
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .200
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.631
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .200
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.631
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .281
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                4.859
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .400
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                6.408
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              2.375
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .065
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                1.604
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.638
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .154
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.653
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .154
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.653
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.022
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.022
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .343
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.444
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .436
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                9.029
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              2 1/2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              2.875
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                2.475
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .120
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.531
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .203
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.793
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .203
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.793
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .276
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.661
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .276
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.661
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                10.01
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .552
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                13.70
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              3
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              3.500
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.029
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .120
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                4.332
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .216
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.576
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .216
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.576
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .300
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                10.25
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .300
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                10.25
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .437
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                14.32
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .600
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                18.58
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              3 1/2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              4.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.472
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .120
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                4.973
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .226
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                9.109
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .226
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                9.109
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .318
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                12.51
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .318
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                12.51
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .636
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                22.85
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              4
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              4.500
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .083
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                3.915
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .120
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                5.613
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .237
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                10.79
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .237
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                10.79
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .281
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                12.66
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .337
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                14.98
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .337
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                14.98
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .437
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                19.01
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .531
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                22.51
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .674
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                27.54
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              4 1/2
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              5.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>&nbsp;</p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .247
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                12.53
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .355
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                17.61
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .710
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                32.53
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              5
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              5.563
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                6.349
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .134
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.770
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .258
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                14.62
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .258
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                14.62
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                20.78
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                20.78
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                27.04
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                32.96
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .750
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                38.55
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              6
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              6.625
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                7.585
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .134
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                9.289
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .280
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                18.97
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .280
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                18.97
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .432
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                28.57
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .432
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                28.57
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .562
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                36.39
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .718
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                45.30
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .864
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                53.16
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              7
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              7.625
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .301
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                23.57
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                38.05
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .875
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                63.08
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              8
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              8.625
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .109
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                9.914
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .148
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                13.40
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                22.36
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .277
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                24.70
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .322
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                28.55
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .322
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                28.55
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .406
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                35.64
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                43.39
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                43.39
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .593
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                50.87
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .718
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                60.63
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .812
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                67.76
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .906
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                74.69
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .875
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                72.42
              </font>
            </p>
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              9
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              9.625
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .342
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                33.90
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                48.72
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              10
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              10.75
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .134
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                15.19
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .165
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                18.65
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                28.04
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .307
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                34.24
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .365
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                40.48
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .365
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                40.48
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                54.74
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .593
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                64.33
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                54.74
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .718
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                76.93
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .843
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                89.20
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.000
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                104.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.125
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                115.7
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              11
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              11.750
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                45.55
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                60.07
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              12
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              12.750
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .156
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                21.07
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .180
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                24.20
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                33.38
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .330
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                43.77
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .406
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                53.53
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                49.56
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .562
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                73.16
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .687
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                88.51
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                65.42
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .843
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                107.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.000
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                125.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.125
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                139.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                160.3
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              14
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              14.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .156
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                23.06
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                36.71
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                45.68
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                54.57
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .437
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                63.37
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                54.57
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .593
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                84.91
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .750
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                106.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                72.09
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .937
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                130.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.093
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                150.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                170.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.406
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                189.1
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              16
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              16.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .165
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                27.90
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                42.05
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                52.36
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                62.58
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                82.77
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                62.58
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .656
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                107.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .843
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                136.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                82.77
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.031
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                164.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                192.3
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.437
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                223.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.593
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                245.1
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              18
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              18.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .165
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                31.43
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                47.39
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                59.03
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .437
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                82.06
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .562
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                104.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                70.59
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .750
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                138.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .937
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                170.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                93.45
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.156
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                208.0
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                244.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.562
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                274.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.781
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                308.5
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              20
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              20.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .188
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                39.78
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                52.73
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                78.60
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                104.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .593
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                122.9
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                78.60
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .812
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                166.4
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.031
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                208.9
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                104.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.280
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                256.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                296.4
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.750
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                341.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.968
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                379.0
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              24
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              24.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                55.37
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                63.41
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                94.62
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .562
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                140.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .687
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                171.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                94.62
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .968
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                238.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.218
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                296.4
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                125.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.531
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                367.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                1.812
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                429.4
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                2.062
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                483.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                2.343
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                541.9
              </font>
            </p>
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              26
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              26.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                85.60
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                136.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                102.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                136.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              28
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              28.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                92.26
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                146.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                182.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                110.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                146.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              30
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              30.000
            </font>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .250
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                79.43
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                98.93
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                157.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                196.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                118.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                157.5
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              32
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              32.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                105.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                168.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                209.4
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .688
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                230.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                126.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                168.2
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              34
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              34.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .344
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                123.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                178.9
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                222.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .688
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                244.8
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                134.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                178.9
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              36
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              36.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .312
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                118.9
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                189.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .625
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                236.1
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .750
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                282.3
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                142.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                189.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              42
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              42.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                166.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                221.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
        <tr>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              48
            </font>
          </td>
          <td width='53' align='center'>
            <font face='Arial' size='1'>
              48.000
            </font>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .375
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                190.7
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            <p style={{ marginTop: '2px', marginBottom: '0' }}>
              <font face='Arial' size='1'>
                .500
              </font>
            </p>
            <p style={{ marginBop: '0', marginBottom: '2px' }}>
              <font face='Arial' size='1' color='#EF3239'>
                253.6
              </font>
            </p>
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='53' align='center'>
            &nbsp;
          </td>
          <td width='55' align='center'>
            &nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default PipeTable
