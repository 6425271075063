import React from 'react'

const StudsAndNutsTable = () => {
  return (
    <table className='border-[1px] max-w-[1440px]'>
      <tbody>
        <tr>
          <td width='170' align='center' colspan='2' bgcolor='#037872'>
            <b>
              <font size='2' face='Arial' color='#FFFFFF'>
                FLANGE
              </font>
            </b>
          </td>
          <td width='680' colspan='10' align='center' bgcolor='#037872'>
            <b>
              <font size='2' face='Arial' color='#FFFFFF'>
                NOMINAL PIPE DIAMETER (INCHES)
              </font>
            </b>
          </td>
        </tr>
        <tr>
          <td width='50' align='center'>
            <b>
              <font face='Arial' size='2'>
                Rating
              </font>
            </b>
          </td>
          <td width='120' align='center'>
            <b>
              <font face='Arial' size='2'>
                Bolting
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                1/2
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                3/4
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                1
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                1 1/4
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                1 1/2
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                2
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                2 1/2
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                3
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                3 1/2
              </font>
            </b>
          </td>
          <td width='68' align='center'>
            <b>
              <font face='Arial' size='2'>
                4
              </font>
            </b>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font face='Arial' size='2'>
                150#
              </font>
            </p>
          </td>
          <td width='120'>
            <font face='Arial' size='2'>
              Number
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font size='2' face='Arial'>
                150#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Diameter
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font size='2' face='Arial'>
                150#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3 1/2
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font size='2' face='Arial'>
                150#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Mach Bolt Length
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              2 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              3
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                300#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              Number
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font color='#FFFFFF' size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                300#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Diameter
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              5/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              5/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              5/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              5/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              3/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                300#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 1/2
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                300#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Mach Bolt Length
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              2 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              2 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              2 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3 3/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font face='Arial' size='2'>
                600#
              </font>
            </p>
          </td>
          <td width='120'>
            <font face='Arial' size='2'>
              Number
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font face='Arial' size='2'>
                600#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Diameter
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              5/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7/8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font face='Arial' size='2'>
                600#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5 3/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                900#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              Number
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font color='#FFFFFF' size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                900#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Diameter
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1 1/8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                900#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              6 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              6 3/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font face='Arial' size='2'>
                1500#
              </font>
            </p>
          </td>
          <td width='120'>
            <font face='Arial' size='2'>
              Number
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              --
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font size='2' face='Arial'>
                1500#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Diameter
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              1
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              1
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              1 1/8
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              --
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              1 1/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left'>
            <p align='center'>
              <font size='2' face='Arial'>
                1500#
              </font>
            </p>
          </td>
          <td width='120'>
            <font size='2' face='Arial'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              4 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5 1/2
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              5 3/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              6 1/4
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              --
            </font>
          </td>
          <td width='68' align='center'>
            <font face='Arial' size='2'>
              7 3/4
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                2500#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              Number
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font color='#FFFFFF' size='2' face='Arial'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                2500#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Diameter
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1 1/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1 1/8
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1 1/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              1 1/2
            </font>
          </td>
        </tr>
        <tr>
          <td width='50' align='left' bgcolor='#037872'>
            <p align='center'>
              <font face='Arial' size='2' color='#FFFFFF'>
                2500#
              </font>
            </p>
          </td>
          <td width='120' bgcolor='#037872'>
            <font size='2' face='Arial' color='#FFFFFF'>
              Stud Bolt Length
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              4 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              5 1/2
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              6
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              6 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              7 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              8 3/4
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              --
            </font>
          </td>
          <td width='68' align='center' bgcolor='#037872'>
            <font face='Arial' size='2' color='#FFFFFF'>
              10
            </font>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default StudsAndNutsTable
