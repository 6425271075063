import { hero_bg } from '../assets'
import { hero } from '../constants'

const Hero = () => {
  return (
    <div
      className='relative min-h-[500px] flex items-center justify-center bg-cover bg-center'
      style={{ backgroundImage: `url(${hero_bg})` }}
    >
      <div className='text-center text-white'>
        <h1
          className='text-3xl md:text-5xl font-bold font-title text-shadow-lg'
          style={{ textShadow: '0 0 50px #047673' }}
        >
          Delivering Superior
        </h1>
        <h1
          className='text-3xl md:text-7xl font-black font-title mt-2 mb-6 text-white'
          style={{ textShadow: '0 0 50px #047673' }}
        >
          {hero.title}
        </h1>
        <p className='text-md md:text-lg mt-4 font-light text-shadow-lg mb-8'>
          {hero.subtitle}
        </p>
        <a
          href='#services'
          className='mt-8 py-2 px-10 bg-logo-1 hover:bg-logo-2 text-white font-normal rounded-[5px]'
        >
          View More
        </a>
      </div>
    </div>
  )
}

export default Hero
