import React from 'react'

const ContactUsInfo = () => {
  return (
    <>
      <div
        className='flex py-20 w-[80%] max-w-[1440px] mx-auto flex-col justify-center'
        id='contactus'
      >
        <div className='flex flex-col text-center '>
          <h1 className='font-bold text-5xl font-title mb-4'>Contact Us</h1>
          <h2 className='font-bold text-2xl font-title mb-6'>
            Need to <span className='text-logo-1'>get in touch</span> with us?
          </h2>
        </div>
        <div className='flex flex-col mx-auto'>
          <h3 className='font-medium text-lg'>
            Sales Or Service Please Contact:
          </h3>
          <div className='text-gray-700 mt-4'>
            <p>
              Don Cell: <span>(780) 975-8538</span>
            </p>
            <p>
              Don email:{' '}
              <a
                href='mailto:don.inkster@67supply.ca'
                className='underline text-blue-600'
              >
                don.inkster@67supply.ca
              </a>
            </p>
          </div>
          <div className='text-gray-700 mt-4'>
            <p>
              Greg Cell: <span>(780) 910-6937</span>
            </p>
            <p>
              Greg email:{' '}
              <a
                href='mailto:greg.peterkin@67supply.ca'
                className='underline text-blue-600'
              >
                greg.peterkin@67supply.ca
              </a>
            </p>
          </div>
          <h3 className='font-medium text-lg mt-6'>Accounting:</h3>
          <div className='text-gray-700 mt-2'>
            <a
              href='mailto:wendy.price@67supply.ca'
              className='underline text-blue-600'
            >
              wendy.price@67supply.ca
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUsInfo
