import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ContactUsInfo from '../components/ContactUsInfo'
// import ContactUs from '../components/ContactUs'

const ContactUsPage = () => {
  return (
    <div>
      <Navbar />
      {/* <ContactUs /> */}
      <ContactUsInfo />
      <Footer />
    </div>
  )
}

export default ContactUsPage
