import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import StudsAndNutsTable from '../../components/StudsAndNutsTable'
import Header from '../../components/Header'
import { hero_bg } from '../../assets'

const StudsAndNuts = () => {
  return (
    <div>
      <Navbar />
      <Header title='Studs & Nuts' img={hero_bg} />
      <div className='w-[80%] mx-auto flex flex-col justify-center items-center my-28'>
        <h1 className='font-bold text-3xl text-center mb-8'>Studs & Nuts</h1>
        <StudsAndNutsTable />
      </div>
      <Footer />
    </div>
  )
}

export default StudsAndNuts
