import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ComingSoom = () => {
  return (
    <div>
      <Navbar />
      <div className='h-[500px] flex flex-col justify-center items-center'>
        <h1 className='text-3xl font-bold'>
          Product info to be uploaded soon!
        </h1>
        <h2 className='text-3xl font-bold'>Please check back later.</h2>
      </div>
      <Footer />
    </div>
  )
}

export default ComingSoom
