import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import PipeTable from '../../components/PipeTable'
import Collapsible from 'react-collapsible'
import SuckerRodTable from '../../components/SuckerRodTable'
import Header from '../../components/Header'
import { hero_bg, pipe_subpage } from '../../assets'
import PolishRods from '../../components/PolishRods'

const Pipe = () => {
  return (
    <div>
      <Navbar />
      <Header title='Pipe' img={hero_bg} />
      <div className='w-[80%] flex flex-col justify-center my-28 text-center max-w-[1440px] mx-auto'>
        <h1 className='font-title text-2xl font-semibold mb-8'>
          Understanding Steel Pipe Sizes, Weights, Dimensions, and More
        </h1>
        <p className='font-light md:w-[70%] mx-auto mb-8'>
          The fundamental conduit facilitating the flow of fluids or gases in
          various industrial applications. Steel Pipe Sizes, Weights,
          Dimensions, Sucker Rod Table, polished rod selection guide, etc.
        </p>
        <div className='md:w-[70%] mx-auto'>
          <img src={pipe_subpage} alt='' />
        </div>
        <div className='mb-4 mt-8 flex justify-center items-center'>
          <Collapsible
            trigger={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Steel Pipe Sizes, Weights, Dimensions
              </button>
            }
            triggerWhenOpen={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Steel Pipe Sizes, Weights, Dimensions
              </button>
            }
            transitionTime={200}
            easing='ease-out'
          >
            <div className='w-full max-w-[1440px] flex items-center'>
              <PipeTable />
            </div>
          </Collapsible>
        </div>
        <div className='mb-4 flex justify-center items-center'>
          <Collapsible
            trigger={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Sucker Rod Table
              </button>
            }
            triggerWhenOpen={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Sucker Rod Table
              </button>
            }
            transitionTime={200}
            easing='ease-out'
          >
            <SuckerRodTable />
          </Collapsible>
        </div>
        <div className='mb-4 flex justify-center items-center'>
          <Collapsible
            trigger={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Polish Rods Selection Guide
              </button>
            }
            triggerWhenOpen={
              <button className='bg-logo-1 hover:bg-logo-2 text-white font-bold py-2 px-4 rounded'>
                Polish Rods Selection Guide
              </button>
            }
            transitionTime={200}
            easing='ease-out'
          >
            <PolishRods />
          </Collapsible>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Pipe
