import React from 'react'
import { home_about } from '../assets'
import { Link } from 'react-router-dom'

const HomeAbout = () => {
  return (
    <section className='flex w-full flex-col md:flex-row bg-logo-2 flex-grow'>
      <div className='flex-1 flex items-stretch'>
        <div
          className='w-full h-full min-h-[350px]'
          style={{
            backgroundImage: `url(${home_about})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </div>

      <div className='flex-1 py-20 md:py-36'>
        <div className='w-[70%] mx-auto'>
          <h1 className='font-title font-bold text-4xl text-w-1 mb-8'>
            About 67 Supply
          </h1>
          <p className='font-light text-[0.95rem] text-w-1 w-[90%] tracking-[0.5px] mb-12'>
            67 Supply INC. is an oilfield supply and service company located in
            Nisku, Alberta. Formed in 2015, 67 Supply INC. is 100% proudly
            Canadian owned. We are continuing to look at strategic new markets
            for future growth. Our offering to the Oil and Gas industry is in
            Production, Drilling, Bottom Hole Pump Products, PVF, Tubular
            Products and many other materials.
          </p>
          {/* eslint-disable-next-line */}
          <Link
            to={'/about'}
            className='text-center text-w-1 hover:text-logo-1 text-[1.2rem] underline-offset-4'
          >
            <span className='underline'>Read More</span> →
          </Link>
        </div>
      </div>
    </section>
  )
}

export default HomeAbout
