import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { hero_bg, pump_truck } from '../../assets'

const BottomHolePump = () => {
  const bottomHolePumpServices = [
    'We have skilled and experienced technicians and sales personnel to help with your downhole pump applications.',
    'We specialize in diagnosing and fixing issues with downhole pumps. We can disassemble, inspect, and repair components such as plungers, barrels, valves, and seals.',
    'We recommend regular maintenance to reduce pump failure and ensure any upgrades or repairs are done to help enhance efficiency.',
    'When a pump unexpectedly fails, we have technicians and replacement parts available to reduce downtime.',
  ]
  return (
    <div>
      <Navbar />
      <Header title='Bottomhole Pump' img={hero_bg} />

      <div className='flex flex-col md:flex-row my-24 max-w-[1440px] mx-auto'>
        <div className='mx-auto flex-1 flex justify-center '>
          <img src={pump_truck} alt='Pump Truck' className='w-[550px]' />
        </div>
        <div className='flex-1'>
          <div className='py-4 md:px-16 px-8'>
            <div>
              <h1 className='text-2xl font-semibold text-d-2'>
                Bottomhole Pump - New and Service
              </h1>
              <h2 className='text-md font-normal text-d-2 mb-4'>
                We supply new, troubleshoot and repair old
              </h2>
              <div className=''>
                {bottomHolePumpServices.map((item, index) => (
                  <p className='font-light mb-2' key={index}>
                    <span className='text-logo-1 text-sm mr-1'>▶</span> {item}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default BottomHolePump
