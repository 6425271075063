import React from 'react'

const Delivery = () => {
  return (
    <section className='flex w-full flex-col bg-gray-100 flex-grow py-20'>
      <div className='w-[80%] max-w-[1440px] mx-auto flex flex-col justify-center'>
        <div className='w-full md:w-[60%] mx-auto flex flex-col justify-center items-center'>
          <h1 className='font-bold text-5xl font-title mb-4'>Delivery</h1>
          <h2 className='font-bold text-2xl font-title mb-2 text-center'>
            All products <span className='text-logo-1'>are delivered</span> to
            your location!
          </h2>
          <h2 className='font-medium text-xl font-title mb-4 text-center text-gray-700'>
            We also arrange pickup and service of products.
          </h2>
          <a
            href='#contactus'
            className='mt-8 py-5 px-10 bg-logo-1 hover:bg-logo-2 text-white font-medium rounded-[5px]'
          >
            Contact Us For More Info
          </a>
        </div>
      </div>
    </section>
  )
}

export default Delivery
