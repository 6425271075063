import { Link } from 'react-router-dom'
import { services } from '../constants'

const Services = () => {
  return (
    <section id='services' className='flex w-full bg-gray-100'>
      <div className='w-[80%] max-w-[1440px] mx-auto my-12 flex flex-col md:flex-row justify-center gap-4'>
        {services.map((service) => (
          <div
            key={service.id}
            className='flex-1 flex-grow shadow bg-white flex flex-col justify-between'
          >
            <div>
              <img
                src={service.imgUrl}
                alt={service.title}
                className='w-full'
              />
              <div className='p-4'>
                <h1 className='font-title text-xl font-semibold mb-4'>
                  {service.title}
                </h1>
                <p
                  className='text-base text-d-2'
                  dangerouslySetInnerHTML={{ __html: service.desc }}
                ></p>
              </div>
            </div>
            <div className='pl-4 mb-6 mt-6'>
              <Link
                to={service.url}
                className='font-semibold text-logo-1 hover:text-logo-2'
              >
                See more →
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default Services
