import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import ServicesPage from './pages/ServicesPage'
import ProductsPage from './pages/ProductsPage'
import NotFound from './pages/NotFound'
import FlangesAndFittings from './pages/Products/FlangesAndFittings'
import StudsAndNuts from './pages/Products/StudsAndNuts'
import Pipe from './pages/Products/Pipe'
import Tools from './pages/Products/Tools'
import News from './pages/News'
import ContactUsPage from './pages/ContactUsPage'
import ComingSoom from './pages/ComingSoom'
import Valves from './pages/Products/Valves'
import Other from './pages/Products/Other'
import BottomHolePump from './pages/Products/BottomHolePump'
import NewsDetail from './components/NewsDetail'

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/products' element={<ProductsPage />} />
        <Route
          path='/products/flanges&fittings'
          element={<FlangesAndFittings />}
        />
        <Route path='/products/studsnuts' element={<StudsAndNuts />} />
        <Route path='/products/pipe' element={<Pipe />} />
        <Route path='/products/bottom-hole-pump' element={<BottomHolePump />} />
        <Route path='/products/tools' element={<Tools />} />
        <Route path='/products/valves' element={<Valves />} />
        <Route path='/products/other' element={<Other />} />
        <Route path='/products/comingsoon' element={<ComingSoom />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsDetail />} />
        <Route path='/contactus' element={<ContactUsPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App
