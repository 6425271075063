import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useNews } from '../lib/useNews'
import Header from '../components/Header'
import { about_bg, empty } from '../assets'

const News = () => {
  const jsonData = useNews()
  return (
    <div>
      <Navbar />
      <Header title='News' img={about_bg} />
      <div className='min-h-[500px] w-[90%] md:w-[70%] mx-auto max-w-[1440px] flex flex-col justify-center items-center py-20'>
        {jsonData?.length > 0 ? (
          jsonData.map((news) => {
            const bgImage = `https://67supply.ca/images/news/${news.imageUrl}`
            return (
              <a
                href={`/news/${news.title}`}
                key={`${news.id}${news.title}`}
                className='w-full flex flex-col md:flex-row justify-center items-center group mb-12'
              >
                <div
                  className='w-[330px] h-[240px] bg-contain bg-no-repeat bg-center'
                  style={{ backgroundImage: `url(${bgImage})` }}
                />
                <div className='px-6 w-[70%]'>
                  <div className='mb-4'>
                    <h1 className='font-title text-4xl font-semibold text-logo-1 group-hover:text-blue-500'>
                      {news.title}
                    </h1>
                    {news.subtitle && (
                      <h2 className='font-title font-medium text-logo-1 mt-1'>
                        {news.subtitle}
                      </h2>
                    )}
                  </div>

                  <p
                    className='text-base text-gray-500 overflow-hidden'
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      lineClamp: 2,
                    }}
                  >
                    {news.description}
                  </p>
                  {news.updated_at && (
                    <p className='text-sm italic text-w-5 mt-3'>
                      {news.updated_at}
                    </p>
                  )}
                  {/* <a href={`/news/${news.title}`}>View More</a> */}
                </div>
              </a>
            )
          })
        ) : (
          <div className='w-[330px] h-[240px] bg-contain bg-no-repeat bg-center text-center'>
            <img src={empty} alt='Empty' />
            <p>No News</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default News
