import React from 'react'
import { useParams } from 'react-router-dom'
import { useNews } from '../lib/useNews'
import Navbar from './Navbar'
import Footer from './Footer'

const NewsDetail = () => {
  const { id } = useParams()
  const jsonData = useNews()
  if (!jsonData) return
  const news = jsonData.find((news) => news.title === id)
  return (
    <>
      <Navbar />
      <div className='w-[300px] md:w-[500px] mx-auto max-w-[1440px] flex flex-col justify-center items-center py-28'>
        <div className='w-[350px]'>
          <img
            src={`https://67supply.ca/images/news/${news.imageUrl}`}
            alt={news.title}
            className='w-full mb-12'
          />
        </div>
        <div>
          <h1 className='font-title text-4xl font-semibold text-logo-1 mb-2'>
            {news.title}
          </h1>
          {news.subtitle && (
            <h2 className='font-title text-lg font-medium text-logo-1 italic'>
              {news.subtitle}
            </h2>
          )}
          {news.updated_at && (
            <p className='text-sm text-w-5 mb-5'>{news.updated_at}</p>
          )}
          <h3 className='mb-6 font-medium text-sm text-logo-2'>
            {news.description}
          </h3>
        </div>
        {news.detail.map((detail) => (
          <p key={detail.id} className='mb-3 '>
            {detail.content}
          </p>
        ))}
      </div>
      <Footer />
    </>
  )
}

export default NewsDetail
