import React from 'react'

const Content = ({ item, index }) => {
  return (
    <div className='flex flex-col lg:flex-row py-8 mt-10 max-w-[1440px]'>
      <div
        className={`order-2 ${
          index % 2 === 0 ? 'lg:order-1' : 'lg:order-2'
        } flex-1 w-[80%] sm:w-[80%] lg:w-full mx-auto pl-4 lg:ml-16`}
      >
        <div className='flex flex-col h-full justify-around py-4 lg:py-6'>
          <div className='ml-0 mr-4 lg:mr-12'>
            <h2 className='font-black font-title text-3xl md:text-4xl mb-10'>
              {item.title}
            </h2>
            <p className='font-light tracking-[0.2px] w-[90%]'>{item.desc}</p>
          </div>
        </div>
      </div>
      <div
        className={`order-1 ${
          index % 2 === 0 ? 'lg:order-2 lg:mr-16' : 'lg:order-1 lg:ml-16'
        } flex-1 w-[90%] sm:w-[80%] lg:w-full mx-auto `}
      >
        {item.imgUrl && (
          <img className='w-full' src={item.imgUrl} alt={item.title} />
        )}
      </div>
    </div>
  )
}

export default Content
