import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import ToolsTable from '../../components/ToolsTable'
import Header from '../../components/Header'
import { hero_bg } from '../../assets'

const Tools = () => {
  return (
    <div>
      <Navbar />
      <Header title='Tools' img={hero_bg} />
      <div className='w-[80%] mx-auto flex flex-col justify-center items-center my-28'>
        <h1 className='font-bold text-3xl text-center mb-8'>
          Wrench Size Charts
        </h1>
        <ToolsTable />
      </div>
      <Footer />
    </div>
  )
}

export default Tools
