import React from 'react'

const Header = ({ title, img }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
      }}
      className='relative min-h-[250px] flex items-center justify-center bg-red-50 bg-cover bg-center'
    >
      <h2 className='font-bold font-title text-3xl md:text-5xl text-w-1'>
        {title}
      </h2>
    </div>
  )
}

export default Header
