import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'

const excelFile = '/news_excel.xlsx'

export const useNews = () => {
  const [jsonData, setJsonData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(excelFile)
        const arrayBuffer = await response.arrayBuffer()
        const data = new Uint8Array(arrayBuffer)
        const workbook = XLSX.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        // Convert to desired JSON format
        const headers = rawData[0]
        const rows = rawData.slice(1)

        const formattedData = rows.map((row, index) => {
          const rowData = {}
          const detail = []

          headers.forEach((header, i) => {
            if (!header.startsWith('p')) {
              rowData[header] = row[i]
            } else if (row[i]) {
              detail.push({ id: header, content: row[i] })
            }
          })

          return { id: index + 1, ...rowData, detail }
        })

        setJsonData(formattedData)
      } catch (error) {
        console.error('Error fetching or processing the Excel file:', error)
      }
    }

    fetchData()
  }, [])
  return jsonData
}
