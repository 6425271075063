import React from 'react'
import Navbar from '../../components/Navbar'
import Header from '../../components/Header'
import { duty_tires_logo, flangesvalves, hero_bg } from '../../assets'
import Footer from '../../components/Footer'

const others = [
  'Light duty tires: We offer an extensive inventory of passenger, light & medium truck as well as farm, ATV, industrial and trailer tires.',
  'Wellhead and Red Iron',
  'Operations Materials',
  'Gauges',
  'Water',
  'belts',
  'instrumentation',
  'wellhead packing',
]

const Other = () => {
  return (
    <div>
      <Navbar />
      <Header title='Other' img={hero_bg} />
      <div className='flex flex-col justify-center items-center max-w-[1440px] mx-auto'>
        <div className='flex-1 pt-28 pb-12 md:px-16 px-8'>
          <div>
            <h1 className='text-3xl font-semibold text-d-2 mb-6'>
              Other Valve Brand Offerings
            </h1>
            <div className='ml-8'>
              {others.map((value, index) => (
                <p className='font-light mb-2' key={index}>
                  <span className='text-logo-1 text-sm mr-1'>▶</span> {value}
                </p>
              ))}
              <p className='font-light mb-2 '></p>
              <span className='text-logo-1 text-sm mr-1'>▶</span>
              <a href='https://insulationsnakes.com/' className='underline'>
                Insulation Snakes
              </a>
            </div>
          </div>
        </div>
        <img
          src={duty_tires_logo}
          alt='duty tires logo'
          className='pb-28 w-[80%] md:w-[70%]'
        />
        <img
          src={flangesvalves}
          alt='duty tires logo'
          className='pb-28 w-[70%] md:w-[50%]'
        />
      </div>
      <Footer />
    </div>
  )
}

export default Other
