import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Products from '../components/Products'
import Services from '../components/Services'
import HomeAbout from '../components/HomeAbout'
import HomeMission from '../components/HomeMission'
// import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
import Delivery from '../components/Delivery'
import ContactUsInfo from '../components/ContactUsInfo'

const Home = () => {
  return (
    <main>
      <Navbar />
      <Hero />
      <Services />
      <Products />
      <Delivery />
      <HomeAbout />
      <HomeMission />
      {/* <ContactUs /> */}
      <ContactUsInfo />
      <Footer />
    </main>
  )
}

export default Home
