import React from 'react'
import Navbar from '../components/Navbar'
import Header from '../components/Header'
import { about_bg } from '../assets'
import Content from '../components/Content'
import Footer from '../components/Footer'
// import OurTeam from '../components/OurTeam'
import { aboutContent } from '../constants'

const About = () => {
  return (
    <div>
      <Navbar />
      <Header title='About Us' img={about_bg} />
      <div className='flex flex-col justify-center items-center pt-8 pb-12'>
        {aboutContent.map((item, index) => (
          <Content key={item.id} item={item} index={index} />
        ))}
      </div>
      {/* <OurTeam /> */}
      <Footer />
    </div>
  )
}

export default About
