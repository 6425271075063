import {
  bottom_hole_pump,
  downhole,
  home_about,
  location,
  mail,
  mission,
  phone,
  // pipe,
  specialty,
} from '../assets'
import { GiTeePipe, GiHexagonalNut, GiValve } from 'react-icons/gi'
import { PiPipeLight } from 'react-icons/pi'
// import { RxColorWheel } from 'react-icons/rx'
import { RiToolsFill } from 'react-icons/ri'
import { GiOilPump } from 'react-icons/gi'

export const navigation = [
  { id: 1, title: 'Home', url: '/', hasDropDown: false },
  { id: 2, title: 'About Us', url: '/about', hasDropDown: false },
  { id: 3, title: 'Services', url: '/services', hasDropDown: false },
  {
    id: 4,
    title: 'Products',
    url: '/products',
    hasDropDown: true,
    subMenu: [
      {
        id: 1,
        title: 'Flanges & Fittings',
        url: '/products/flanges&fittings',
        desc: 'Essential components for seamless and secure connections in industrial piping systems.',
      },
      {
        id: 7,
        title: 'Bottom Hole Pump (Sales & Service)',
        desc: 'We supply new, troubleshoot and repair old.',
        url: '/products/bottom-hole-pump',
      },

      {
        id: 2,
        title: 'Pipe',
        url: '/products/pipe',
        desc: 'The fundamental conduit facilitating the flow of fluids or gases in various industrial applications.',
      },
      {
        id: 3,
        title: 'Studs and nuts',
        url: '/products/studsnuts',
        desc: 'Vital fastening elements ensuring the stability and integrity of industrial structures and equipment.',
      },
      {
        id: 4,
        title: 'Tools',
        url: '/products/tools',
        desc: 'Specialized equipment engineered to tackle the demanding challenges of oilfield tasks, ensuring optimal performance and safety.',
      },
      {
        id: 5,
        title: 'Valves',
        url: '/products/valves',
        desc: 'Offering a diverse selection of steel valves from trusted brands.',
      },
      {
        id: 6,
        title: 'Other',
        desc: 'We offer supplementary materials and equipment to address diverse oilfield needs comprehensively.',
        url: '/products/other',
      },
    ],
  },
  { id: 5, title: 'News', url: '/news', hasDropDown: false },
]

// export const navOther = [
//   {
//     id: 1,
//     title: 'Delivery',
//     url: '/products/comingsoon',
//     desc: 'Essential components for seamless and secure connections in industrial piping systems.',
//   },
// ]

export const hero = {
  title: 'Oilfield Service',
  subtitle: 'Proudly Canadian owned and operated',
}

export const services = [
  {
    id: 1,
    title: 'Bottom Hole Pump (Sales & Service)',
    desc: "A vital component in oilfield operations, facilitating the extraction of crude oil from deep beneath the earth's surface. Engineered for durability and efficiency, our bottom hole pumps ensure continuous production in challenging environments.",
    imgUrl: bottom_hole_pump,
    url: '/products/bottom-hole-pump',
  },
  {
    id: 2,
    title: 'Downhole Experts',
    desc: 'Our team of seasoned professionals specializes in navigating the complexities of downhole operations, ensuring optimal performance and efficiency. With years of experience and a deep understanding of downhole technologies, we deliver tailored solutions to meet your oilfield needs.',
    imgUrl: downhole,
    url: '/services',
  },
  {
    id: 3,
    title: 'Specialty',
    desc: `We partner with <a style="text-decoration: underline; color: #047673; font-weight:bold" href='https://www.genoilfield.com/home.html' target='_blank'>General Oilfield Equipment</a> that is a proud agent for Wellhead Systems Inc, Wellmark - Level Controls, Regulators, and Valves.`,
    imgUrl: specialty,
    url: '/services',
  },
]

const productIconSize = 52

export const products = [
  {
    id: 1,
    title: 'Flanges & Fittings',
    desc: 'Our flanges and fittings ensure seamless integration and reliable performance in oilfield operations.',
    url: '/products/flanges&fittings',
    icon: <GiTeePipe size={productIconSize} />,
  },
  {
    id: 2,
    title: 'Pipe',
    desc: 'Our high-quality pipes deliver durability and efficiency for oil and gas applications.',
    url: '/products/pipe',
    icon: <PiPipeLight size={productIconSize} />,
  },
  {
    id: 3,
    title: 'Valves',
    desc: 'Offering a diverse selection of steel valves from trusted brands.',
    url: '/products/valves',
    icon: <GiValve size={productIconSize} />,
  },
  {
    id: 4,
    title: 'Studs & Nuts',
    desc: 'Our studs and nuts provide secure fastening solutions engineered for resilience and precision.',
    url: '/products/studsnuts',
    icon: <GiHexagonalNut size={productIconSize} />,
  },
  {
    id: 5,
    title: 'Tools',
    desc: 'Our innovative oilfield tools streamline operations and enhance productivity.',
    url: '/products/tools',
    icon: <RiToolsFill size={productIconSize} />,
  },
  {
    id: 6,
    title: 'Other',
    desc: 'We offer supplementary materials and equipment to address diverse oilfield needs comprehensively.',
    url: '/products/other',
    icon: <GiOilPump size={productIconSize} />,
  },
]

export const aboutContent = [
  {
    id: 1,
    title: 'Leading the Way in Oilfield Solutions and Service',
    desc: '67 Supply INC. is an oilfield supply and service company located in Nisku, Alberta. Formed in 2015, 67 Supply INC. is 100% proudly Canadian owned. Our owners bring over 40 years of experience in the Oil and Gas Industry, ensuring expertise and reliability in every service we provide. We are continuing to look at strategic new markets for future growth. Our offering to the Oil and Gas industry includes Production, Drilling, Bottom Hole Pump Products, PVF, Tubular Products, and many other materials.',
    imgUrl: home_about,
  },
  {
    id: 2,
    title: 'Mission',
    desc: 'We are actively exploring strategic new markets to fuel our future growth. Our comprehensive offerings to the oil and gas industry encompass production, drilling, bottom hole pump products, PVF, tubular products, and various other materials.',
    imgUrl: mission,
  },
]

export const footerContactInfo = [
  {
    id: 1,
    title: 'address',
    icon: location,
    contents: [
      {
        id: 1,
        info: 'Bay #3, 1206 10 St #3, Nisku, AB T9E 8K2',
      },
      {
        id: 2,
        info: 'Mailing - Box 3900, Leduc, AB T9E 6M8',
      },
    ],
  },
  {
    id: 2,
    title: 'Sales or Service please phone',
    icon: phone,
    contents: [
      {
        id: 1,
        info: 'Don Cell: (780) 975-8538',
      },
      {
        id: 2,
        info: 'Greg Cell: (780) 910-6937',
      },
    ],
  },
  {
    id: 3,
    title: 'email',
    icon: mail,
    contents: [
      {
        id: 1,
        info: 'don.inkster@67supply.ca',
        isEmail: true,
        emailName: 'Don email',
      },
      {
        id: 2,
        info: 'greg.peterkin@67supply.ca',
        isEmail: true,
        emailName: 'Greg email',
      },
      {
        id: 3,
        info: 'wendy.price@67supply.ca',
        isEmail: true,
        emailName: 'Wendy (accounting) email',
      },
    ],
  },
]

export const polishRodsContent = {
  id: 'polishRodsContent_1',
  main: 'AOT Polished Rods and Polished Rod Couplings are manufactured in accordance to the latest edition of the API specification 11B. AOT Polished Rods and couplings are furnished with cold-formed fully rolled threads. This technique ensures a perfect thread on each and every pin. The burnished thread surface that results from rolled threads improves resistance to corrosion and reduces abrasion within the thread. The work hardened flank provides increased surface tensile, yield and shear strength. Due to pressure deformation, a residual compressive stress system builds up at the thread root, which counteracts tensile loading. When compared to cut threads, the load capacity of the rolled thread is increased by 6 - 12%.',
  details: [
    {
      id: 1,
      title: 'Alloy Steel',
      desc: 'Manufactured from chromium - molybdenum alloy steel (4140) and designed for light to heavy loads at any depth in mild to medium corrosive well fluids that are effectively inhibited against corrosion. This polished rod has a minimum tensile strength of 125,000 PSI and has been alloyed to improve its hardenability and increase its resistance to corrosion and abrasion. It can be used with all AOT Sucker Rod grades.',
    },
    {
      id: 2,
      title: 'Norloy Steel',
      desc: 'Manufactured from a nickel - chromium - molybdenum alloy steel (8620) and designed for light to heavy loads in severely corrosive well fluids that are effectively inhibited against corrosion. The norloy polished rod has a minimum tensile strength of 100,000 PSI. The addition of nickel to this polished rod further increases its corrosion resistance and improves toughness. It can be used with all Norris Sucker Rod grades but is ideally suited for use in conjunction with type 75 rods.',
    },
    {
      id: 3,
      title: 'Stainless Steel',
      desc: 'Manufactured from Type 431 stainless steel. This polished rod has a minimum tensile strength of 115,000 PSI and has the best corrosion resistance properties of all AOT grades. It has excellent tensile and torque strength and good toughness, making it ideally suited for progressing cavity pump application. This grade exhibits excellent resistance to a wide variety of corrosion media including salt water. Designed for any loads at any depth where corrosion is a problem or where other AOT Polished Rod grades are not applicable. This polished rod can be used with all AOT Sucker Rod grades.',
    },
    {
      id: 4,
      title: 'Sprayloy',
      desc: 'These hard surfaced polished rods are made from cold finished 1045 carbon steel with a hard spraymetal surface applied to the O.D. They are recommended for abrasive and corrosive conditions under moderate to heavy loads.',
    },
    {
      id: 5,
      title: 'Nitronic 50',
      desc: 'Manufactured from XM-19 High Strength Stainless Steel. Nitronic 50 is nitrogen-strengthened austenitic stainless steel that provides excellent resistance to highly corrosive environments, sulfide stress cracking and to intergranular attack. This polished rod has a minimum tensile strength of 135,000 psi.',
    },
  ],
}
