import React from 'react'

const ToolsTable = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td width='375'>
            <p align='center' style={{ marginTop: '0', marginBottom: '0' }}>
              <b>
                <font size='2' face='Arial'>
                  Hex Bolts &amp; Nuts ASTM A307A, Gr 5,
                </font>
              </b>
            </p>
            <p align='center' style={{ marginTop: '0', marginBottom: '5px' }}>
              <b>
                <font size='2' face='Arial'>
                  304 &amp; 316 Stainless Steel
                </font>
              </b>
            </p>
          </td>
          <td width='50'>&nbsp;</td>
          <td width='50'>&nbsp;</td>
          <td width='375'>
            <p align='center' style={{ marginTop: '0', marginBottom: '0' }}>
              <b>
                <font size='2' face='Arial'>
                  HEAVY Hex Bolts &amp; Nuts A307, Gr B,
                </font>
              </b>
            </p>
            <p align='center' style={{ marginTop: '0', marginBottom: '5px' }}>
              <b>
                <font size='2' face='Arial'>
                  A193 B7, B8 (304), B8M (316) Stainless Steel
                </font>
              </b>
            </p>
          </td>
        </tr>
        <tr>
          <td width='375' valign='top'>
            <table
              border='1'
              width='375'
              cellspacing='0'
              cellpadding='0'
              bordercolor='#000000'
            >
              <tbody>
                <tr>
                  <td width='145' bgcolor='#037872'>
                    <p align='center'>
                      <b>
                        <font face='Arial' size='2' color='#FFFFFF'>
                          Bolt &amp; Nut Diameter
                        </font>
                      </b>
                    </p>
                  </td>
                  <td width='230' colspan='2' bgcolor='#037872'>
                    <p align='center'>
                      <b>
                        <font face='Arial' size='2' color='#FFFFFF'>
                          Wrench Size
                        </font>
                      </b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      1/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      7/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      0.4375
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      5/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      1/2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      0.5000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      3/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      9/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      0.5625
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      7/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      5/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      0.6250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font face='Arial' size='2' color='#FFFFFF'>
                      1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font face='Arial' size='2' color='#FFFFFF'>
                      3/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font face='Arial' size='2' color='#FFFFFF'>
                      0.7500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      9/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font face='Arial' size='2' color='#FFFFFF'>
                      13/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font face='Arial' size='2' color='#FFFFFF'>
                      0.8125
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      5/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      15/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      0.9375
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 1/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1.1250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      7/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 5/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1.13125
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1 1/2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1.5000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 1/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1 11/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1.6875
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 1/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1 7/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1.8750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 3/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2 1/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2.0625
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.2500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 5/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 7/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.4375
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 3/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 5/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.6250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 7/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 13/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.8125
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3.0000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      2 1/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3 3/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3.3750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      2 1/2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3 3/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      3.7500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      2 3/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      4 1/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      4.1250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4 1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4.5000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4 7/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4.8750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      5 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      5.2500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 3/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      5 5/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      5.6250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      6"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      6.0000
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td width='50'>&nbsp;</td>
          <td width='50'>&nbsp;</td>
          <td width='375' valign='top'>
            <table
              border='1'
              width='375'
              cellspacing='0'
              cellpadding='0'
              bordercolor='#000000'
            >
              <tbody>
                <tr>
                  <td width='145' bgcolor='#037872'>
                    <p align='center'>
                      <b>
                        <font face='Arial' size='2' color='#FFFFFF'>
                          Bolt &amp; Nut Diameter
                        </font>
                      </b>
                    </p>
                  </td>
                  <td width='230' colspan='2' bgcolor='#037872'>
                    <p align='center'>
                      <b>
                        <font face='Arial' size='2' color='#FFFFFF'>
                          Wrench Size
                        </font>
                      </b>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      1/2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      7/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      0.8750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font face='Arial' size='2'>
                      5/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      11/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font face='Arial' size='2'>
                      1.0625
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      3/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1 1/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1.2500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      7/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1 7/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      1.4375
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 5/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1.6250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 1/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 13/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1.8125
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.0000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      1 3/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 3/16"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2.1875
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 1/2"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2 3/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2.3750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 5/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2 9/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2.5625
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 3/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2 3/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2.7500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      1 7/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2 15/16"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      2.9375
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 1/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3.1250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3.5000
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 1/2"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3 7/8"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      3.8750
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      2 3/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4 1/4"
                    </font>
                  </td>
                  <td width='115' align='center' bgcolor='#037872'>
                    <font size='2' face='Arial' color='#FFFFFF'>
                      4.2500
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      3"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      4 5/8"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      4.6250
                    </font>
                  </td>
                </tr>
                <tr>
                  <td width='145' align='center'>
                    <font size='2' face='Arial'>
                      3 1/4"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      5"
                    </font>
                  </td>
                  <td width='115' align='center'>
                    <font size='2' face='Arial'>
                      5.0000
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ToolsTable
