import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { hero_bg, valves_header } from '../../assets'

const castValves = [
  'Size: ¼” through 8” (Larger sizes available upon request)',
  'Class: 150# through 2500#',
  'Connection: Threaded, Socketweld, Flanged',
  'Grade(s): SA105, SA350 LF2, WCB, LCC',
]
const otherValves = [
  'Ball: Balon, Predator, GWC',
  'Butterfly: Norris, CNC',
  'Check: Argus, Crown, DHV, Bonney',
  'Gate: Bonney, DHV, DSI, Newco',
  'Globe: Bonney, DHV, DSI, Newco',
  'Needle: MDI',
  'Pigging: Argus',
  'Relief: Baird, WSI',
]

const Valves = () => {
  return (
    <div>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${hero_bg})`,
        }}
        className='relative min-h-[250px] flex flex-col items-center justify-center bg-cover bg-center'
      >
        <h2
          className='font-bold font-title text-3xl md:text-5xl text-w-1 mt-4'
          style={{ textShadow: '0 0 50px #047673' }}
        >
          Valves
        </h2>
      </div>
      <div className='flex flex-col md:flex-row justify-center md:px-12 max-w-[1440px] mx-auto'>
        <div className='flex-1 border-b-[2px] md:border-b-0 md:border-r-[2px] border-[#000000] py-28 lg:px-16 px-8'>
          <div>
            <div className='flex w-full justify-center mb-16'>
              <img src={valves_header} alt='valves icon' width='200px' />
            </div>

            <h1 className='text-3xl font-semibold text-d-2 mb-6'>
              CAST AND FORGED STEEL VALVES: ANSI / API
            </h1>
            {castValves.map((valve, index) => (
              <p className='font-light mb-2' key={index}>
                <span className='text-logo-1 text-sm mr-1'>▶</span> {valve}
              </p>
            ))}
          </div>
        </div>
        <div className='flex-1 py-28 md:px-16 px-8'>
          <div>
            <h1 className='text-3xl font-semibold text-d-2 mb-6'>
              Other Valve Brand Offerings
            </h1>
            <div className='ml-8'>
              {otherValves.map((valve, index) => (
                <p className='font-light mb-2' key={index}>
                  <span className='text-logo-1 text-sm mr-1'>▶</span> {valve}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Valves
