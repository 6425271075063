import React from 'react'

const SuckerRodTable = () => {
  return (
    <div>
      <table class='table-auto text-center w-full mt-4'>
        <thead>
          <tr className='bg-logo-1 text-w-1'>
            <th class='font-bold p-1 border'>AOT Type</th>
            <th class='font-bold p-1 border'>54</th>
            <th class='font-bold p-1 border'>78</th>
            <th class='font-bold p-1 border'>75</th>
            <th class='font-bold p-1 border'>96</th>
            <th class='font-bold p-1 border'>97</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>AISI Grade</td>
            <td class='p-1 border'>C-1541-VM</td>
            <td class='p-1 border'>A-4142M</td>
            <td class='p-1 border'>A-4330-M</td>
            <td class='p-1 border'>A-4138-M</td>
            <td class='p-1 border'>A-4330-M</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'></td>
            <td class='p-1 border'>RANGE</td>
            <td class='p-1 border'>RANGE</td>
            <td class='p-1 border'>RANGE</td>
            <td class='p-1 border'>RANGE</td>
            <td class='p-1 border'>RANGE</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Carbon</td>
            <td class='p-1 border'>.40/.44</td>
            <td class='p-1 border'>.38/.43</td>
            <td class='p-1 border'>.30/.34</td>
            <td class='p-1 border'>.38/.42</td>
            <td class='p-1 border'>.30/.34</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Manganese</td>
            <td class='p-1 border'>1.40/1.60</td>
            <td class='p-1 border'>.80/1.00</td>
            <td class='p-1 border'>.80/1.00</td>
            <td class='p-1 border'>1.10/1.40</td>
            <td class='p-1 border'>.80/1.00</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Phosphorus</td>
            <td class='p-1 border'>.040 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Sulfur</td>
            <td class='p-1 border'>.040 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
            <td class='p-1 border'>.040 Mx.</td>
            <td class='p-1 border'>.035 Mx.</td>
            <td class='p-1 border'>.040 Mx.</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Silicon</td>
            <td class='p-1 border'>.15/.35</td>
            <td class='p-1 border'>.15/.35</td>
            <td class='p-1 border'>.15/.35</td>
            <td class='p-1 border'>.20/.35</td>
            <td class='p-1 border'>.15/.35</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Copper</td>
            <td class='p-1 border'>.35 Mx</td>
            <td class='p-1 border'>.15/.35 Mx</td>
            <td class='p-1 border'>.15/.35 Mx</td>
            <td class='p-1 border'>.15/.35 Mx</td>
            <td class='p-1 border'>.15/.35 Mx</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Nickel</td>
            <td class='p-1 border'>.25 Mx</td>
            <td class='p-1 border'>.25 Mx</td>
            <td class='p-1 border'>1.65/1.85</td>
            <td class='p-1 border'>.35 Mx.</td>
            <td class='p-1 border'>1.65/1.85</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Chromium</td>
            <td class='p-1 border'>.20 Mx</td>
            <td class='p-1 border'>.90/1.10</td>
            <td class='p-1 border'>.80/1.00</td>
            <td class='p-1 border'>.55/.85 Mx</td>
            <td class='p-1 border'>.80/1.00</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Molybdenum</td>
            <td class='p-1 border'>.06 Mx.</td>
            <td class='p-1 border'>.15/.25</td>
            <td class='p-1 border'>.20/.30</td>
            <td class='p-1 border'>.25/.35</td>
            <td class='p-1 border'>.20/.30</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>Vanadium</td>
            <td class='p-1 border'>.07/.09</td>
            <td class='p-1 border'>.030/.045</td>
            <td class='p-1 border'>.07/.09</td>
            <td class='p-1 border'>.045/.065</td>
            <td class='p-1 border'>.07/.09</td>
          </tr>
          <tr>
            <td class='font-bold p-1 border bg-logo-1 text-w-1'>
              Columbium(Niobium)
            </td>
            <td class='p-1 border'></td>
            <td class='p-1 border'></td>
            <td class='p-1 border'></td>
            <td class='p-1 border'>.027/.043</td>
            <td class='p-1 border'></td>
          </tr>
        </tbody>
      </table>
      <table class='table-auto text-center w-full mt-4'>
        <thead>
          <tr className='bg-logo-1 text-w-1'>
            <th class='font-bold p-1 border'>Rod Pin Size</th>
            <th class='font-bold p-1 border'>Nominal Diameter of Pin</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class='p-1 border '>5/8"</td>
            <td class='p-1 border'>15/16"</td>
          </tr>
          <tr>
            <td class='p-1 border '>3/4"</td>
            <td class='p-1 border'>1-1/16"</td>
          </tr>
          <tr>
            <td class='p-1 border '>7/8"</td>
            <td class='p-1 border'>1-3/16"</td>
          </tr>
          <tr>
            <td class='p-1 border '>1"</td>
            <td class='p-1 border'>1-3/8"</td>
          </tr>
          <tr>
            <td class='p-1 border '>1 1/8"</td>
            <td class='p-1 border'>1-9/16"</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SuckerRodTable
